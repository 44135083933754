import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../css/landing.css";
import Downloadbutton from "../Components/Downloadbutton";
import Swal from "sweetalert2";
import Footer from "../uiComponents/Footer";
import close from "../../assets/images/global-circularCrossIcon.png";
import logo from "../../assets/images/Header_profile.png";
import whatsapp from "../../assets/images/whatsapp.png";
import closeicon from "../../assets/images/global-purple-battleIcon.png";
import youtube from "../../assets/images/global-ytPlayIcon.png";

import { IoCall } from "react-icons/io5";

export default function Landing() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  const [open, setOpen] = useState(false);
  const [userAllData, setUserAllData] = useState();
  const [commission, setcommission] = useState();
  const [whatappNumber, setWhatappNumber] = useState();
  const [refer, setrefer] = useState();
  const [WebSitesettings, setWebsiteSettings] = useState("");
  const [blockMessage, setBlockMessage] = useState("");
  const [userBlock, setUserBlock] = useState(0);
  const [msg, setmsg] = useState("");

  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    setcommission(data.commission);
    setWhatappNumber(data.CompanyMobile);
    setrefer(data.refer);
    setmsg(data.msg);
    return setWebsiteSettings(data);
  };

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        const passworduser = res.data.Password;
      })

      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
        }
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
    }
    role();
    fetchData();
  }, [userBlock]);

  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const webname = "Aj King";
  return (
    <>
      <div></div>

      {userBlock > 0 ? <div>{blockMessage}</div> : <div>Login Panel</div>}

      <div className="leftContainer">
        <div className="main-area" style={{ paddingTop: "35px" }}>
          <div className="header_top_message">
            <span>Commission:5% ◉ Referral: 3% For All Games</span>
          </div>
          <div className="header_top_message">
            <span>{msg}</span>
          </div>
          {/* <div className="collapseCard-container">
            <div className="collapseCard">
              <a href="#!" style={{ textDecoration: "none" }}>
                <div
                  className="collapseCard-body"
                  style={{
                    height: 64,
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text">How to win money?</div>
                  <picture className="collapseCard-closeIcon">
                    <img
                      className="position-relative"
                      src={close}
                      alt="Close"
                      width="14px"
                      height="14px"
                    />
                  </picture>
                </div>
              </a>
              <div
                className="collapseCard-header"
                style={{ left: 22, transition: "left 0.3s ease 0s" }}
              >
                <picture>
                  <img height="10px" width="14px" src={youtube} alt="" />
                </picture>
                <div className="collapseCard-title ml-1 mt-1">Video Help</div>
              </div>
            </div>
          </div> */}
          <div className="collapseCard-container">
            <div className="collapseCard">
              <a href="/Kyc2" style={{ textDecoration: "none" }}>
                <div
                  className="collapseCard-body"
                  style={{
                    height: 64,
                    opacity: 1,
                    transition: "height 0.3s ease 0s, opacity 0.3s ease 0s",
                  }}
                >
                  <div className="collapseCard-text">
                    Please Compleate Your KYC
                  </div>
                  <picture className="collapseCard-closeIcon">
                    <img
                      className="position-relative"
                      src={close}
                      alt="Close"
                      width="14px"
                      height="14px"
                    />
                  </picture>
                </div>
              </a>
              <div
                className="collapseCard-header"
                style={{ left: 22, transition: "left 0.3s ease 0s" }}
              >

              </div>
            </div>
          </div>

          <section className="games-section padding_new">
            <div class="d-flex align-items-center games-section-title">
              Our Games
            </div>
            <div class="games-section-headline mt-2 mb-1">
              <img src={closeicon} alt="" /> is for Battles and
              <img
                className="ml-1"
                src="/images/global-blue-tournamentIcon.png"
                alt=""
              />
              is for Tournaments. <span>Know more here.</span>
            </div>

            <div className="games-section-headline mt-2 mb-1">
              <div className="games-window">
                <Link
                  className="gameCard-container"
                  to={`/Homepage/Ludo%20Classics%20Lite`}
                >
                  <span className="blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>
                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_classic.png"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">Ludo Classic</div>
                  <picture className="gameCard-icon">
                    <img src={closeicon} alt="" />
                  </picture> */}
                </Link>
                <Link className="gameCard-container">
                  <span className="blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_popular.png"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">Snake</div>
                  <picture className="gameCard-icon">
                    <img src={closeicon} alt="" />
                  </picture> */}
                </Link>

                <Link className="gameCard-container">
                  <span className="blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/ludo-SNK.png"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">Manual Room Card</div>
                  <picture className="gameCard-icon">
                    <img src={closeicon} alt="" />
                  </picture> */}
                </Link>

                <Link className="gameCard-container">
                  <span className="blink text-danger d-block text-right">
                    ◉ LIVE
                  </span>

                  <picture className="gameCard-image rounded-lg">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/rummy.png"
                      }
                      alt=""
                    />
                  </picture>
                  {/* <div className="gameCard-title">Aviator</div>
                  <picture className="gameCard-icon">
                    <img src={closeicon} alt="" />
                  </picture> */}
                </Link>
              </div>
            </div>

            <div className="w1 my-3">
              {/* <button className="w2"> */}
              <Downloadbutton />
              {/* </button> */}
            </div>
            <footer className="footer_landing">
              <div className="footer_logo">
                <Link to="/">
                  <img
                    src={logo}
                    alt="AJ King Ludo"
                  />
                </Link>
              </div>
              <div className="footer_dop_icon">
                <Link to="#" target="_blanck">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M7.253 18.494l.724.423A7.953 7.953 0 0 0 12 20a8 8 0 1 0-8-8c0 1.436.377 2.813 1.084 4.024l.422.724-.653 2.401 2.4-.655zM2.004 22l1.352-4.968A9.954 9.954 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10a9.954 9.954 0 0 1-5.03-1.355L2.004 22zM8.391 7.308c.134-.01.269-.01.403-.004.054.004.108.01.162.016.159.018.334.115.393.249.298.676.588 1.357.868 2.04.062.152.025.347-.093.537a4.38 4.38 0 0 1-.263.372c-.113.145-.356.411-.356.411s-.099.118-.061.265c.014.056.06.137.102.205l.059.095c.256.427.6.86 1.02 1.268.12.116.237.235.363.346.468.413.998.75 1.57 1l.005.002c.085.037.128.057.252.11.062.026.126.049.191.066a.35.35 0 0 0 .367-.13c.724-.877.79-.934.796-.934v.002a.482.482 0 0 1 .378-.127c.06.004.121.015.177.04.531.243 1.4.622 1.4.622l.582.261c.098.047.187.158.19.265.004.067.01.175-.013.373-.032.259-.11.57-.188.733a1.155 1.155 0 0 1-.21.302 2.378 2.378 0 0 1-.33.288 3.71 3.71 0 0 1-.125.09 5.024 5.024 0 0 1-.383.22 1.99 1.99 0 0 1-.833.23c-.185.01-.37.024-.556.014-.008 0-.568-.087-.568-.087a9.448 9.448 0 0 1-3.84-2.046c-.226-.199-.435-.413-.649-.626-.89-.885-1.562-1.84-1.97-2.742A3.47 3.47 0 0 1 6.9 9.62a2.729 2.729 0 0 1 .564-1.68c.073-.094.142-.192.261-.305.127-.12.207-.184.294-.228a.961.961 0 0 1 .371-.1z" />
                  </svg>
                  <span> <a
                className="cxy flex-column"
                href={
                  "https://wa.me/+919057936002?text=hello%20admin%20i%20need%20help"
                }
              >Chat With US</a></span>
                </Link>
              </div>
            </footer>

            <div class="footer_about_content">


              <div class="item">
                <h5><Link to="/term-condition">Terms And Condition</Link></h5>
              </div>

              <div class="item">
                <h5><Link to="/privacy-policy">Privacy Policy</Link></h5>
              </div>
              <div class="item">
                <h5><Link to="/refund-policy">Refund/Cancellation Policy</Link></h5>
              </div>
              <div class="item">
                <h5><Link to="/contact-us">Contact Us</Link></h5>
              </div>
              <div class="item">
                <h5><Link to="/responsible-gaming">Responsible Gaming</Link></h5>
              </div>
              <div class="item">
                <h5><Link to="/platformcommision">Platform Commission</Link></h5>
              </div>


            </div>
            {/*  <div className="accordion">
              <div className="accordion-item">
                <div
                  className={`accordion-title ${
                    activeIndex === 0 ? "active" : ""
                  }`}
                  onClick={() => onItemClick(0)}
                >
                  <img src={logo} width={50} />

                  <span className="accordianheader">
                    {" "}
                    Terms, Privacy, Support
                  </span>
                </div>
                <div
                  className={`accordion-content ${
                    activeIndex === 0 ? "open" : ""
                  }`}
                >
                  <div
                    className="px-3 overflow-hidden"
                    style={{ height: 130, transition: "height 0.5s ease 0s" }}
                  >
                    <div className="row footer-links">
                      <Link className="col-6" to="/term-condition">
                        Terms &amp; Condition
                      </Link>
                      <Link className="col-6" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                      <Link className="col-6" to="/refund-policy">
                        Refund/Cancellation Policy
                      </Link>
                      <Link className="col-6" to="/contact-us">
                        Contact Us
                      </Link>
                      <Link className="col-6" to="/responsible-gaming">
                        Responsible Gaming
                      </Link>
                      <Link className="col-6" to="/platformcommision">
                        Platform Commission
                      </Link>
                     <Link className="col-6" to="/tds-policy">
      TDS Policy
    </Link>
    <Link className="col-6" to="/gst-policy">
      GST Policy
    </Link> 
                    </div>
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="footer-divider" />
            <div className="py-4">
              <div className="footer-text-bold">
                Our Business &amp; Products
              </div>
              <br />
              <div className="footer-text">
                We are an HTML5 game-publishing company and our mission is to
                make accessing games fast and easy by removing the friction of
                app-installs.
              </div>
              <br />
              <div className="footer-text">
                {webname} is a skill-based real-money gaming platform accessible
                only for our users in India. It is accessible on{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://winbro.in"
                >
                  https://ajking.in
                </a>
                . On {webname}, users can compete for real cash in Tournaments
                and Battles. They can encash their winnings via popular options
                such as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile
                Recharges etc.
              </div>
              <br />
              <div className="footer-text-bold">Our Games</div>
              <br />
              <div className="footer-text">
                {webname} has a wide-variety of high-quality, premium HTML5
                games. Our games are especially compressed and optimised to work
                on low-end devices, uncommon browsers, and patchy internet
                speeds.
              </div>
              <br />
              <div className="footer-text">
                We have games across several popular categories: Arcade, Action,
                Adventure, Sports &amp; Racing, Strategy, Puzzle &amp; Logic. We
                also have a strong portfolio of multiplayer games such as Ludo,
                Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese
                Checkers and more! Some of our popular titles are: Escape Run,
                Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends.
                If you have any suggestions around new games that we should add
                or if you are a game developer yourself and want to work with
                us, don't hesitate to drop in a line at{" "}
                <a href="mailto:info@ajking.in">info@ajking.in</a>!
              </div>
            </div>
          </section>


          <div class="calllink">
            <div className="content">
              <div class="pulse">
                <IoCall className="text-dark" />
              </div>
            </div>
          </div>
          <div class="whatapp_link">
            <a href="https://api.whatsapp.com/send?phone=919057936002&amp;text=">
              <img src={whatsapp} />
            </a>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}
