import React, { useEffect, Profiler, useState } from "react";
// import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import "./Component-css/Downloadbutton.css?v=0.1";
// let deferredPrompt;
const Downloadbutton = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <div
      className=" "
    // style={{
    //   position: "fixed",
    //   bottom: 0,
    //   width: "100%",
    //   maxWidth: 480,
    //   // backgroundColor: "#3A427D"
    // }}
    >
      <div className="text-center w2">
        <button
          onClick={onClick}
          className="btn btn-primary btn-sm bg-transparent w2 border-0"
          style={{ fontWeight: 500, backgroundColor: "#3A427D" }}
        >
          <i className="fa fa-android text-white mr-2"></i>
          <span className="w3">
            {" "}
            DOWNLOAD OUR APP
          </span>
          <i className="fa fa-download text-white ml-2"></i>
        </button>
      </div>
    </div>
  );
};

export default Downloadbutton;
