import React, { useEffect, useState } from "react";
import "../css/kyc.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Pan.module.css";
import "../css/Loader.css";
import axios from "axios";
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Manual_Payment = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;
  const [amount, setAmount] = useState(0);
  const [utrNumber, setUtrNumber] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Selectoption");
  const [upiId, setUpiId] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [qrCode, setQrCode] = useState(""); // Added qrCode state
  const [upiSettings, setUpiSettings] = useState({ isEnabled: false, upiId: "" });
  const [accountSettings, setAccountSettings] = useState([]);
  const [qrSetting, setQRSetting] = useState(null);
  const [websiteSettings, setWebsiteSettings] = useState({ isAccountOn: true, isUpiOn: true, isQROn: true });
  const [screenshot, setScreenshot] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const amountParam = queryParams.get('amount');
    const utrParam = queryParams.get('utr');
    
    if (amountParam) {
      setAmount(amountParam);
      
      if (parseFloat(amountParam) <= 2000) {
        setPaymentMethod('QR');
      }
    }
    
    if (utrParam) {
      setUtrNumber(utrParam);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(baseUrl + "settings/data");
        setWebsiteSettings(response.data);
        console.log("Website Settings:", response.data); 
      } catch (error) {
        console.error("Error fetching website settings: ", error);
      }
    };
    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    if (websiteSettings.isUpiOn) {
      const fetchUpiSettings = async () => {
        try {
          const response = await axios.get(`${baseUrl}User/upisettings`);
          if (response.data.status === 'success') {
            const enabledSetting = response.data.upiSettings.find(setting => setting.isEnabled);
            if (enabledSetting) {
              setUpiSettings({ isEnabled: true, upiId: enabledSetting.upiId });
              setUpiId(enabledSetting.upiId);
            }
          } else {
            console.error("Failed to fetch UPI settings");
          }
        } catch (error) {
          console.error("Error fetching UPI settings:", error);
        }
      };
      fetchUpiSettings();
    }
  }, [baseUrl, websiteSettings.isUpiOn]);

  useEffect(() => {
    if (websiteSettings.isAccountOn) {
      const fetchAccountSettings = async () => {
        try {
          const response = await axios.get(`${baseUrl}User/accountSettings`);
          if (response.data.status === 'success') {
            const enabledAccounts = response.data.accountSettings.filter(setting => setting.isEnabled);
            setAccountSettings(enabledAccounts);
            if (enabledAccounts.length > 0) {
              setAccountNumber(enabledAccounts[0].accountNumber);
              setBeneficiaryName(enabledAccounts[0].beneficiaryName);
              setIfscCode(enabledAccounts[0].ifscCode);
            }
          } else {
            console.error("Failed to fetch account settings");
          }
        } catch (error) {
          console.error("Error fetching account settings:", error);
        }
      };
      fetchAccountSettings();
    }
  }, [baseUrl, websiteSettings.isAccountOn]);

  useEffect(() => {
    if (websiteSettings.isQROn) {
      const fetchQRSetting = async () => {
        try {
          const response = await axios.get(`${baseUrl}User/qrSettings`);
          if (response.data.status === 'success' && response.data.qrSettings.length > 0) {
            setQRSetting(response.data.qrSettings[0]);
          } else {
            setQRSetting(null);
          }
        } catch (error) {
          console.error("Error fetching QR setting:", error);
        }
      };
      fetchQRSetting();
    }
  }, [baseUrl, websiteSettings.isQROn]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert("Text copied to clipboard!");
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  // const handleConfirm = async () => {
  //   const access_token = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`,
  //     "Content-Type": "multipart/form-data"
  //   };
  
  //   if (!/^\d{12}$/.test(utrNumber)) {
  //     Swal.fire({
  //       title: "Invalid UTR Number",
  //       text: "Please enter a valid 12-digit UTR Number",
  //       icon: "error",
  //       confirmButtonText: "OK",
  //     });
  //     return;
  //   }
  
  //   try {
  //     const formData = new FormData(); 
  //     formData.append("paymentMethod", paymentMethod);
  //     if (paymentMethod === 'UPI') {
  //       formData.append("upiId", upiId);
  //     } else if (paymentMethod === 'QR' && screenshot) {
  //       formData.append("qrScreenshot", screenshot); 
  //     } else if (paymentMethod === 'Account') {
  //       formData.append("accountNumber", accountNumber);
  //       formData.append("ifscCode", ifscCode);
  //       formData.append("beneficiaryName", beneficiaryName);
  //     }
  //     formData.append("amount", amount);
  //     formData.append("urtID", utrNumber);
  
  //     const response = await axios.post(
  //       `${baseUrl}user/manualpayment`,
  //       formData,
  //       { headers: headers }
  //     );
  
  //     const data = response.data;
  //     if (data.status === "ok") {
  //       Swal.fire({
  //         title: "Payment Successfully Added",
  //         icon: "success",
  //         confirmButtonText: "OK",
  //       });
  //       window.location.reload();
  //     } else {
  //       Swal.fire({
  //         title: data.message,
  //         icon: "error",
  //         confirmButtonText: "OK",
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error confirming payment: ", error);
  //     alert("Error confirming payment");
  //   }
  // };


  const handleConfirm = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "multipart/form-data"
    };
  
    
    if (!utrNumber && !screenshot) {
      Swal.fire({
        title: "Missing Information",
        text: "Please provide either a valid UTR number or upload a QR screenshot.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
  
   
    if (utrNumber && !/^\d{12}$/.test(utrNumber)) {
      Swal.fire({
        title: "Invalid UTR Number",
        text: "Please enter a valid 12-digit UTR Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
  
    try {
      const formData = new FormData(); 
      formData.append("paymentMethod", paymentMethod);
      
      if (paymentMethod === 'UPI') {
        formData.append("upiId", upiId);
      } else if (paymentMethod === 'QR' && screenshot) {
        formData.append("qrScreenshot", screenshot); // Attach the screenshot file if selected
      } else if (paymentMethod === 'Account') {
        formData.append("accountNumber", accountNumber);
        formData.append("ifscCode", ifscCode);
        formData.append("beneficiaryName", beneficiaryName);
      }
  
      // Add UTR number if provided
      if (utrNumber) {
        formData.append("urtID", utrNumber);
      }
      
      formData.append("amount", amount);
  
      const response = await axios.post(
        `${baseUrl}user/manualpayment`,
        formData,
        { headers: headers }
      );
  
      const data = response.data;
      if (data.status === "ok") {
        Swal.fire({
          title: "Payment Successfully Added",
          icon: "success",
          confirmButtonText: "OK",
        });
        window.location.reload();
      } else {
        Swal.fire({
          title: data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error confirming payment: ", error);
      Swal.fire({
        title: "Error",
        text: "There was an error confirming the payment.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  
  
  
  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  return (
    <div>
      <div className="leftContainer">
        <div className="px-4 mt-5 pt-4 text-center">
          <h3>Payment Amount</h3>
          <h5 className="text-danger">₹ {amount}</h5>
          <div className="form-group">
            <label htmlFor="paymentMethod">Payment Method</label>
            <select 
              id="paymentMethod"
              className="form-control"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              disabled={!websiteSettings.isUpiOn && !websiteSettings.isAccountOn && !websiteSettings.isQROn}
            >
              <option value="Selectoption">Select Payment Option</option>
              {websiteSettings.isUpiOn && <option value="UPI">UPI</option>}
              {websiteSettings.isAccountOn && <option value="Account">Account</option>}
              {websiteSettings.isQROn && <option value="QR">QR Code</option>}
            </select>
          </div>
          {paymentMethod === 'UPI' && websiteSettings.isUpiOn ? (
            <>
              {upiSettings.isEnabled ? (
                <>
                 <div className="d-flex justify-content-between align-items-center border border-1 p-2 rounded">
                  <div>
                    <p className="mb-0">Pay to UPI:</p>
                    <p className="mb-0 text-danger">{upiSettings.upiId}</p>
                  </div>
                  <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(upiSettings.upiId)}>Copy</div>
                </div>
                 <div className="mt-3">
                 <label htmlFor="utrNumber" className="mt-2">Enter UTR Number</label>
                 <input
                   type="text"
                   id="utrNumber"
                   className="form-control"
                   value={utrNumber}
                   onChange={(e) => setUtrNumber(e.target.value)}
                 />
               </div>
               <div className="confimbtn text-light py-2 px-3" onClick={handleConfirm}>Confirm</div>



               </>
              ) : (
                <div className="text-danger mt-3">
                  <p>UPI payments are currently disabled.</p>
                </div>
              )}
            </>
          ) : paymentMethod === 'Account' && websiteSettings.isAccountOn ? (
            <div className="form-group mt-3">
              {accountSettings.length > 0 ? (
                <div>
                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 mb-3 rounded">
                    <p className="mb-0">Account Number:</p>
                    <p className="mb-0 text-danger">{accountNumber}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(accountNumber)}>Copy</div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 mb-3 rounded">
                    <p className="mb-0">Beneficiary Name:</p>
                    <p className="mb-0 text-danger">{beneficiaryName}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(beneficiaryName)}>Copy</div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center border border-1 p-2 rounded">
                    <p className="mb-0">IFSC Code:</p>
                    <p className="mb-0 text-danger">{ifscCode}</p>
                    <div className="btn btn-primary text-light py-2 px-3" onClick={() => handleCopy(ifscCode)}>Copy</div>
                  </div>
                  <div className="mt-3">
                 <label htmlFor="utrNumber" className="mt-2">Enter UTR Number</label>
                 <input
                   type="text"
                   id="utrNumber"
                   className="form-control"
                   value={utrNumber}
                   onChange={(e) => setUtrNumber(e.target.value)}
                 />
               </div>
               <div className="confimbtn text-light py-2 px-3" onClick={handleConfirm}>Confirm</div>
                </div>
              ) : (
                <div className="text-danger mt-3">
                  <p>No enabled bank accounts available.</p>
                </div>
              )}
            </div>
          )
          : paymentMethod === 'QR' && websiteSettings.isQROn ? (
            <div className="mb-3">
            {qrSetting ? (
              <>
                <img 
                  src={`https://apifront.ajking.in/public${qrSetting.qrCodeUrl}`} 
                  alt="QR Code" 
                  className="qrimage" 
                />
                <div className="form-group mt-3">
                  <label htmlFor="qrScreenshot">Upload Payment Screenshot</label>
                  {/* <input 
                    type="file" 
                    id="qrScreenshot" 
                    className="form-control" 
                    onChange={handleFileChange}
                  /> */}
                </div>
                <Button variant="success" className="text-white" onClick={handleShow}>
        Upload Payment Details
      </Button>
              </>
            ) : (
              <div className="text-danger mt-3">
                <p>QR Code settings are not available.</p>
              </div>
            )}
          </div>
          ) : (<div className="text-danger mt-3">
              <p>No payment methods are currently enabled.</p>
            </div>
          )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
     
        <div className="text-left mt-3">
            
            <div className="mt-3">
              <label htmlFor="utrNumber" className="mt-2">Enter UTR Number</label>
              <input
                type="text"
                id="utrNumber"
                className="form-control"
                value={utrNumber}
                onChange={(e) => setUtrNumber(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <div className="upload-btn-wrapper">
  <button className="btncustum">Upload a file</button>
  <div className="form-group mt-3">
                  <input 
                    type="file" 
                    id="qrScreenshot" 
                    className="form-control" 
                    onChange={handleFileChange}
                    name="myfile"
                  />
                </div>
</div>
            </div>
          </div>
          <div className="confimbtn text-light py-2 px-3" onClick={handleConfirm}>Confirm</div>

        </Modal.Body>
        
      </Modal>
         
        </div>
      </div>
      <Rightcontainer />
    </div>
  );
};

export default Manual_Payment;
