import React from 'react'

export default function Platformcommision() {
  return (
    <div>
       <div className="leftContainer">
      <div className="main-area">
        <section className="paddingtop-60">
          <div className="m-3">
          <h4><strong>Platform Commission</strong></h4>

           
            <div className="row">
              <div className="col-12 contentpagesbody">
                <p>
                  Platfrom commission is taken by each participant equally in
                  every contest or battle. Commission of any battle/contest is
                  shown to participant before joining the contest/battle.
                </p>
               <h5 className="heading_sub">Below 500 cash games,</h5>
                <ul>
                  <li>
                    We charge 5-30% commission depending on the entry amount.
                  </li>
                </ul>
               <h5 className="heading_sub">Above 500 cash games,</h5>
                <ul>
                  <li>We charge flat 5% commission on each battle.</li>
                </ul>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    </div>
  )
}
