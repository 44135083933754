import React from "react";

export default function Playwithfriends() {
  const webname = "Aj King"

  return (
    <div className="leftContainer">
      <div className="main-area" style={{ paddingTop: "35px" }}>
        <section className="games-section p-3 mt-3 py-4">
          <div className="d-flex align-items-center games-section-title">
            Play With Friends in below games:
          </div>
          <div className="games-section-headline mt-2 mb-1">
            <div className="games-window">
              <div className="gameCard-container">
                <span className="blink text-danger d-block text-right">
                  ◉ LIVE
                </span>
                <a className="gameCard" href="/play-with-friends/kb_ludo">
                  <picture className="gameCard-image">
                    <img width="100%" src="/images/games/kb_ludo.jpeg" alt="" />
                  </picture>

                  <picture className="">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/rummy.png"
                      }
                      alt=""
                      width="100%"
                    />
                  </picture>
                  <div className="gameCard-title ludoname">{webname} Ludo</div>
                </a>
              </div>
              <div className="gameCard-container">
                <span className="blink text-danger d-block text-right">
                  ◉ LIVE
                </span>
                <a
                  className="gameCard"
                  href="/play-with-friends/kb_ludo_classic"
                >
                  <picture className="">
                    <img
                      width="100%"
                      src={
                        process.env.PUBLIC_URL +
                        "/Images/LandingPage_img/Ludo_classic.png"
                      }
                      alt=""
                    />
                  </picture>
                  <div className="gameCard-title ludoname">Ludo Classic</div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
