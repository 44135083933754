import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "../css/layout.css";
import "../css/login.css";
import whatsapp from "../../assets/images/whatsapp.png";
import { IoCall } from "react-icons/io5";

export default function Footer() {
  return (
    <>
      <div class="calllink">
            <div className="content">
              <div class="pulse">
                <IoCall className="text-dark" />
              </div>
            </div>
          </div>
          <div class="whatapp_link">
            <a href="https://api.whatsapp.com/send?phone=919057936002&amp;text=">
              <img src={whatsapp} />
            </a>
          </div>
          
      {/* <div
      > */}
        {/* <div className="footer_menu">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/"><i class="fa-solid fa-house"></i></Link>
            <Link to="/wallet"><i class="fa-solid fa-wallet"></i></Link>
            <Link to="/profile"><i class="fa-solid fa-user"></i></Link>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
}
