import React from "react";
import css from "../Modulecss/Home.module.css";
import { Link } from "react-router-dom";
import avtar1 from "../Avtar/Avatar1.png";
import avtar2 from "../Avtar/Avatar2.png";
import avtar3 from "../Avtar/Avatar3.png";
import avtar4 from "../Avtar/Avatar4.png";

export default function RunningCard({ runnig, user, winnAmount, game_type }) {
  const avatars = [avtar1, avtar2, avtar3, avtar4];
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const REACTAPPBACKENDLIVEProfileimageAPI =
  process.env.REACT_APP_BACKEND_LIVE_Profile_image_API;
const nodeMode = process.env.NODE_ENV;
if (nodeMode === "development") {
  var baseUrl = REACTAPPBACKENDLIVEProfileimageAPI;
} else {
  baseUrl = REACTAPPBACKENDLIVEProfileimageAPI;
}

  function getRandomAvatar() {
    const randomIndex = Math.floor(Math.random() * avatars.length);
    return avatars[randomIndex];
  }

  const randomAvatar = getRandomAvatar();

  return (
    <>

      {/* ****************************** */}
      <div className={`${css.betCard} mt-2 bg-none`}>

        <div className="d-flex justify-content-between">
          <span
            className={`${css.betCardTitle} betCard-title pl-3 d-flex align-items-center text-uppercase border_danger`}
          >
            PLAYING FOR
            <img
              className="mx-1"
              src={
                process.env.PUBLIC_URL +
                "/Images/LandingPage_img/global-rupeeIcon.png"
              }
              alt=""
              width="21px"
            />
            {runnig.Game_Ammount}
          </span>
          {(user == runnig.Accepetd_By._id || user == runnig.Created_by._id) && (
            <Link
              className={`${css.bgSecondary} ${css.playButton} ${css.cxy
                } position-relative m-2 mx-1 ${runnig.Status == "conflict" ? "bg-danger" : "bg-success"
                }`}
              style={{
                right: "0px",
                top: "-6px",
                padding: "10px 17px",
              }}
              to={{
                pathname: `/viewgame1/${runnig._id}`,
                state: { prevPath: window.location.pathname },
              }}
            >
              view
            </Link>
          )}
          <div
            className={`${css.betCardTitle} betCard-title d-flex align-items-center text-uppercase border_danger`}
          >
            <span className="ml-auto mr-3">
              PRIZE
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              {runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
            </span>
          </div>
        </div>
        <div className="py-1 row">
          <div className="pr-3 text-center col-5">
            {/* {console.log("https://nightking.com/user.png")} */}
            <div className="pl-2">
              {runnig.Created_by.avatar ? (
                <img
                  src={
                    `https://kycptr.ajking.in/${runnig.Created_by && runnig.Created_by.avatar}`
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = { getRandomAvatar };
                  }}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={getRandomAvatar()}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                  }}
                />
              )}
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                {runnig.Created_by.Name}
              </span>
            </div>
          </div>
          <div className="pr-3 text-center col-2 cxy">
            <div>
              <img
                src={process.env.PUBLIC_URL + "/Images/Homepage/versus.png"}
                alt=""
                width="21px"
              />
            </div>
          </div>
          <div className="text-center col-5">
            <div className="pl-2">
              {/* {console.log(getRandomAvatar(),'getRandomAvatar')} */}
              {/* {console.log(runnig.Accepetd_By)} */}


              {runnig?.Accepetd_By.avatar ? (
                <img
                  src={
                    `https://kycptr.ajking.in/${runnig.Accepetd_By && runnig.Accepetd_By.avatar}`
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getRandomAvatar();
                  }}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                  }}
                />
              ) : (
                <img
                  src={getRandomAvatar()}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    borderBottomRightRadius: "50%",
                    borderBottomLeftRadius: "50%",
                  }}
                />
              )}
            </div>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                {runnig.Accepetd_By.Name}
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* *********************************************** */}

      <div>

        {/* <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              1000
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                1950
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Rohit</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Lokesh</span>
              </div>
            </div>
          </div>
        </div>
        <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              500
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                920
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Deepak</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Manoj</span>
              </div>
            </div>
          </div>
        </div>
        <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              1500
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                2760
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Gorav</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Suraj</span>
              </div>
            </div>
          </div>
        </div>
        <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              2000
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                3680
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Rohit</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Lokesh</span>
              </div>
            </div>
          </div>
        </div> */}





        {/* <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              1000
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                1950
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Rohit</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Lokesh</span>
              </div>
            </div>
          </div>
        </div>
        <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              500
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                920
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Deepak</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Manoj</span>
              </div>
            </div>
          </div>
        </div>
        <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              1500
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                2760
              </span>
            </div>
          </div>

          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Gorav</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Suraj</span>
              </div>
            </div>
          </div>

        </div> */}

        {/* <div className="Home_betCard__zY3HS mt-1">
          <div className="d-flex">
            <span className="Home_betCardTitle__f9yhC pl-3 d-flex align-items-center text-uppercase">
              PLAYING FOR
              <img
                className="mx-1"
                src="/Images/LandingPage_img/global-rupeeIcon.png"
                alt=""
                width="21px"
              />
              2000
            </span>
            <div className="Home_betCardTitle__f9yhC d-flex align-items-center text-uppercase">
              <span className="ml-auto mr-3">
                PRIZE
                <img
                  className="mx-1"
                  src="/Images/LandingPage_img/global-rupeeIcon.png"
                  alt=""
                  width="21px"
                />
                3680
              </span>
            </div>
          </div>
          <div className="py-1 row">
            <div className="pr-3 text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar1}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Rohit</span>
              </div>
            </div>
            <div className="pr-3 text-center col-2 cxy">
              <div>
                <img src="/Images/Homepage/versus.png" alt="" width="21px" />
              </div>
            </div>
            <div className="text-center col-5">
              <div className="pl-2">
                <img
                  src={avtar2}
                  alt=""
                  width="33px"
                  height="33px"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div style={{ lineHeight: 1 }}>
                <span className="Home_betCard_playerName__kOsaM">Lokesh</span>
              </div>
            </div>
          </div>
        </div> */}
  




      </div>





    </>


  );
}
